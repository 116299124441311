import React, { Component } from "react";
import { Link } from "react-router-dom";

class TopFooter extends Component {
  render() {
    return (
      <div className="widget-infomation">
        <ul className="infomation-footer">
          <li>
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <Link to="#" title="">
              shreesaienterprises341@gmail.com
            </Link>
          </li>
          <li>
            <i className="fa fa-phone" aria-hidden="true"></i>
            <Link to="#" title="">
              +91 9876543210
            </Link>
          </li>
          <li>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            <Link to="#" title="">
              Sector-1, Chandrasekharpur, Bhubaneswar, 751024
            </Link>
          </li>
        </ul>
        {/* <!-- /.infomation-footer --> */}
      </div>
      /* <!-- /.widget-infomation --> */
    );
  }
}

export default TopFooter;
