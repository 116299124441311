import Home01 from './Home01'
import TeamGrid from './TeamGrid'
import OverView from './OverView'
import AboutUs from './AboutUs'
import Services from './Services'
import Portfolio3 from './Portfolio3'
import Contact01 from './Contact01'



const routes = [
    { path: '/', component: Home01},
    { path: '/about-v1', component: AboutUs},
    { path: '/about-v2', component: TeamGrid},
    { path: '/about-v3', component: OverView},
    { path: '/services-v1', component: Services},


    { path: '/portfolio-v1', component: Portfolio3},
 

    { path: '/contact-v1', component: Contact01},


    
]

export default routes;