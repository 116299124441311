import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TabPortfolio from './TabPortfolio';
import hotel1 from '../../../img/hotel/Room1.jpg'
import hotel2 from '../../../img/hotel/Room2.jpg'
import hotel3 from '../../../img/hotel/Room3.jpg'
import hotel4 from '../../../img/hotel/Room4.jpg'
import hotel5 from '../../../img/hotel/Room5.jpg'
import hotel6 from '../../../img/hotel/Room6.jpg'
import hotel7 from '../../../img/hotel/Room7.jpg'
import hotel8 from '../../../img/hotel/Room8.jpg'
import hotel9 from '../../../img/Bar/Bar.png'
import Construction1 from '../../../img/Construction/Frame 1.png'
import Construction2 from '../../../img/Construction/Frame 2.png'
import Construction3 from '../../../img/Construction/Frame 3.png'
import Construction4 from '../../../img/Construction/Frame 4.png'
import Construction5 from '../../../img/Construction/Frame 5.png'
import Construction6 from '../../../img/Construction/Frame 6.png'
import Construction7 from '../../../img/Construction/Frame 7.png'
import Construction8 from '../../../img/Construction/Frame 8.png'
import Construction9 from '../../../img/Construction/Frame 9.png'
import Construction10 from '../../../img/Construction/Frame 10.png'
import Construction11 from '../../../img/Construction/Frame 11.png'



class PortfolioLoad extends Component {
	constructor(props) {
        super(props);
        this.state = {
			itemcontent: [
				{
					id: '1',
					srcimg: hotel8,
					classitem: 'item business hotel trading',
				},
				{
					id: '2',
					srcimg: hotel2,
					classitem: 'item business hotel trading',
				},
				{
					id: '3',
					srcimg: hotel3,
					classitem: 'item business hotel trading',
				},
				{
					id: '4',
					srcimg: hotel4,
					classitem: 'item business hotel trading',
				},
				{
					id: '5',
					srcimg: hotel5,
					classitem: 'item business hotel trading',
				},
				{
					id: '6',
					srcimg: hotel6,
					classitem: 'item business hotel trading',
				},
				{
					id: '7',
					srcimg: hotel7,
					classitem: 'item business hotel trading',
				},
				{
					id: '8',
					srcimg: hotel1,
					classitem: 'item business hotel trading',
				},
				{
					id: '9',
					srcimg: hotel9,
					classitem: 'item business bar trading',
				},
				{
					id: '10',
					srcimg: Construction1,
					classitem: 'item business construction trading',
				},
				{
					id: '11',
					srcimg: Construction2,
					classitem: 'item business construction trading',
				},
				{
					id: '12',
					srcimg: Construction3,
					classitem: 'item business construction trading',
				},
				{
					id: '13',
					srcimg: Construction4,
					classitem: 'item business construction trading',
				},
				{
					id: '14',
					srcimg: Construction5,
					classitem: 'item business construction trading',
				},
				{
					id: '15',
					srcimg: Construction6,
					classitem: 'item business construction trading',
				},
				{
					id: '16',
					srcimg: Construction7,
					classitem: 'item business construction trading',
				},
				{
					id: '17',
					srcimg: Construction8,
					classitem: 'item business construction trading',
				},
				{
					id: '18',
					srcimg: Construction9,
					classitem: 'item business construction trading',
				},
				{
					id: '19',
					srcimg: Construction10,
					classitem: 'item business construction trading',
				},
				{
					id: '20',
					srcimg: Construction11,
					classitem: 'item business construction trading',
				},
				

				// {
				// 	id: '11',
				// 	srcimg: hotel2,
				// 	classitem: 'item business savings trading',
				// 	title: 'Business Solutions',
				// 	category: 'Services /',
				// 	subcategory: 'Trading',
				// },
				// {
				// 	id: '2',
				// 	srcimg: 'images/portfolio/02.jpg',
				// 	classitem: 'item management finance',
				// 	title: 'Money Market',
				// 	category: 'Services /',
				// 	subcategory: 'Trading',
				// },
				// {
				// 	id: '3',
				// 	srcimg: 'images/portfolio/03.jpg',
				// 	classitem: 'item savings services',
				// 	title: 'MO Insurance',
				// 	category: 'Services /',
				// 	subcategory: 'Trading',
				// },
				// {
				// 	id: '4',
				// 	srcimg: 'images/portfolio/04.jpg',
				// 	classitem: 'item finance trading',
				// 	title: 'Financial Report',
				// 	category: 'Management /',
				// 	subcategory: 'Trading',
				// },
				// {
				// 	id: '5',
				// 	srcimg: 'images/portfolio/05.jpg',
				// 	classitem: 'item invoicing finance',
				// 	title: 'Event Finder',
				// 	category: 'Invoicing',
				// 	subcategory: '',
				// },
				// {
				// 	id: '6',
				// 	srcimg: 'images/portfolio/06.jpg',
				// 	classitem: 'item services savings',
				// 	title: 'Oak Tree Solutions',
				// 	category: 'Business',
				// 	subcategory: '',
				// },
				// {
				// 	id: '7',
				// 	srcimg: 'images/portfolio/07.jpg',
				// 	classitem: 'item trading management',
				// 	title: 'Investment Planning',
				// 	category: 'Business /',
				// 	subcategory: 'Trading',
				// },
				// {
				// 	id: '8',
				// 	srcimg: 'images/portfolio/08.jpg',
				// 	classitem: 'item invoicing services',
				// 	title: 'Enterprise Loan',
				// 	category: 'Finance',
				// 	subcategory: '',
				// }
			]
        }
    }
    render() {
        return (
				/* <!-- Portfolio --> */
				<section className="flat-row pd-portfolio-s3" id="work">
					<TabPortfolio />
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="dividers portfolio"></div>
								<div className="flat-portfolio">             
									<div className="portfolio-wrap grid one-three clearfix">
										{
											this.state.itemcontent.map(data =>(
												<div key={data.id} className={data.classitem}>
													<div className="wrap-iconbox">
														<div className="featured-post">
															<img src={data.srcimg} alt="img" />
														</div>
														<div className="title-post">
															<Link to="#">{data.title}</Link>
														</div>
														<div className="category-post">
															<Link to="#" title="">{data.category} </Link>
															<Link to="#" title=""> {data.subcategory}</Link>
													</div>
												</div> 
											</div>
										/* <!-- portfolio-item --> */
											))
										}
									</div>
									{/* <!-- /.portfolio-wrap --> */}
								</div>
								{/* <!-- /.flat-portfolio --> */}
							</div>

						</div>
					</div>
				</section> 

				 
        );
    }
}

export default PortfolioLoad;