const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
    {
        id: 2,
        name: 'Services',
        linkmenu: '/services-v1',
    },
    {
        id: 3,
        name: 'Our Team',
        linkmenu: '/about-v2',
    },
    {
        id: 4,
        name: 'Gellary',
        linkmenu: '/portfolio-v1',
    },
    {
        id: 5,
        name: 'About',
        linkmenu: '/about-v3',
    },
   
    
   
 
    {
        id: 6,
        name: 'Contact',
        linkmenu: '/contact-v1',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Contact 01',
        //         links: '/contact-v1',
        //         submenu: undefined
        //     },
        //     {
        //         id: 2,
        //         sub: 'Contact 02',
        //         links: '/contact-v2',
        //         submenu: undefined
        //     },
            

        // ],
    }
]

export default menus;