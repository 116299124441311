import React, { Component } from "react";
import { Link } from "react-router-dom";
import med from "../../../../img/Service/medition.png";
import cargo from "../../../../img/Service/cargo.png";
import Construction from "../../../../img/Service/construction.png";
import supply from "../../../../img/Service/Supply.png";
import bar from "../../../../img/Service/bar.png";
import hotel from "../../../../img/Service/Hotel.png";
class MainServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleServices: [
        {
          id: 1,
          title: "What We Can Offer You",
          description: "",
        },
      ],
      imagebox: [
        {
          id: "1",
          imgsrc: med,
          title: "Medicine",
          description:
            "Medicine can treat diseases and improve your health. Our aim is to make the world healthy.",
        },
        {
          id: "2",
          imgsrc: hotel,
          title: "Hotel & Lodge",
          description:
            "We give you more of what you want and less of what you don't need. Come, Stay and enjoy your day.",
        },
        {
          id: "3",
          imgsrc: cargo,
          title: "Marintime transport",
          description:
            "We are dealing with transport or shipment of cargo through the waterway from dhamara port.",
        },
        {
          id: "4",
          imgsrc: Construction,
          title: "Construction",
          description:
            "We are dealing with infrastructure projects such as roads, bridges, railways, airport, etc.",
        },
        {
          id: "5",
          imgsrc: supply,
          title: "Metarial transport",
          description:
            "Movement of an object such as soil, bricks, iron, cement, etc. from one place to another place.",
        },
        {
          id: "9",
          imgsrc: bar,
          title: "Bar & Resturent",
          description:
            "Spending some time in a bar makes people relax from their busy daily schedules for some time.",
        },
      ],
    };
  }
  render() {
    return (
      <section className="flat-row pd-services-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {this.state.titleServices.map((data) => (
                <div className="title-section center s1" key={data.id}>
                  <h2>{data.title} </h2>
                  <p className="sub-title-section">{data.description} </p>
                </div>
              ))}
              <div className="dividers dividers-imagebox"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="wrap-imagebox-grid">
                {this.state.imagebox.map((data) => (
                  <div
                    className="flat-imagebox services-grid item"
                    key={data.id}
                  >
                    <div className="flat-imagebox-inner">
                      <div className="flat-imagebox-image">
                        <img src={data.imgsrc} alt="img" />
                      </div>
                      <div className="flat-imagebox-header">
                        <h3 className="flat-imagebox-title">
                          <Link to="#">{data.title}</Link>
                        </h3>
                      </div>
                      <div className="flat-imagebox-content">
                        <div className="flat-imagebox-desc">
                          {data.description}
                        </div>
                        <div className="flat-imagebox-button"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MainServices;
