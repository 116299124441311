import React, { Component } from 'react';
class BoxView  extends Component {
	constructor(props) {
        super(props);
        this.state = {
   
            content: [
                {
                    id: '1',
                    title: 'Want to know more about Us?',
                    description: 'We start our company from the bottom of the root. Our dream was to make establish a company so we started with Construction, Construction material transport, and Maritime transport but we notice that these are seasonal businesses so we diversify our services to a permanent structure and started with a new domain Bar & Restaurant, Hotel & Lodge, and Medsion. Because of our Hardwork, Punctuality, Humbel behavior, and good team, we archive this much success within 4 years. If we archive this many things in this short time then we are thinking we will grow in future also. Everything is possible becouse of one person CEO Mr. Sidharth Mohanty.',
                }
            ],
            blogtimeline: [
                {
                    id: '1',
                    year: 'March 2018',
                    title: 'Infrastructure construction',
                    description: 'This was the time when we started our company. We had no idea how far we would go, we weren’t even sure that we would be able to survive for a few years. What drove us to start the company was the understanding that we could provide a service no one else was providing.',
                },
                {
                    id: '2',
                    year: 'April 2019',
                    title: 'Start with Marintime transport',
                    description: 'We got a transport order from one of the clients we take this as a challenge and started this service.',
                },
                {
                    id: '3',
                    year: 'Octber 2019',
                    title: 'Metarial transport',
                    description: 'From the construction business, we started giving construction material transport service.',
                },
                {
                    id: '4',
                    year: 'July 2020',
                    title: 'Hotel',
                    description: 'From the sessional business, we started a permanent structure business.',
                },
                {
                    id: '5',
                    year: 'April 2021',
                    title: 'Bar',
                    description: 'We added new venture on our business.',
                },
                {
                    id: '6',
                    year: 'Dec 2021',
                    title: 'Medicine',
                    description: 'For helping the people we started this business we are providing generic Medicine.',
                },
                {
                    id: '7',
                    year: 'Expected 2024',
                    title: 'Hospital',
                    description: 'We are planing to creat one hospital for needy people this is our dream project.',
                }
                
            ]
            
        }
    }
    render() {
        return (
            <div>
               
                    {
                        this.state.content.map(data =>(
                            <div className="box-content" key={data.id} >
                                <div className="title">{data.title}</div>
                                <p>{data.description}</p>
                                <div className="dividers dividers-bc-v4"></div>
                            </div>
                            ))
                    }
                
                    {
                        this.state.blogtimeline.map(data =>(
                            <div className="flat-text-block-timeline" key={data.id} >
                                <div className="year">{data.year}</div>
                                <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p>{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
								
        )
                                
    }
}

export default BoxView;