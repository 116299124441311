import React, { Component } from 'react';
import { Header , Footer, Loader } from '../layouts/general';
import {  PortfolioLoad } from '../layouts/portfolio';
class Portfolio3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Porfolio Default'
                }
            ],
        
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />

                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }


                    <PortfolioLoad />
                    
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Portfolio3;