import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class ServicesBoxs1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    classicon: 'icon-rounded clipboard',
                    title: 'Medicine',
                    description: 'Medicine can treat diseases and improve your health. Our aim is to make the world healthy.', 
                },
                {
                    id: 2,
                    classicon: 'icon-rounded line-chart',
                    title: 'Hotel & Lodge',
                    description: "We give you more of what you want and less of what you don't need. Come, Stay and enjoy your day. ",
                },
                {
                    id: 3,
                    classicon: 'icon-rounded clock',
                    title: 'Marintime transport',
                    description: "We are dealing with transport or shipment of cargo through the waterway from dhamara port.",
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to="#" title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ServicesBoxs1;