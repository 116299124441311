import React, { Component } from "react";

class TextMainFooter extends Component {
  render() {
    return (
      <div className="widget widget-text">
        <img
          src="images/logo-blog.png"
          alt="Shree sai"
          width={157}
          height={29}
          data-retina="images/logo-blog.png"
          data-width={157}
          data-height={29}
        />
      </div>
      /* <!-- /.widget-text --> */
    );
  }
}

export default TextMainFooter;
